import RestaurantBlock from "../../RestaurantBlock/View/RestaurantBlock.view";
import doner_17_ker from "../../../Resources/doner_logo_17kerulet.png";
import doner_vecses from "../../../Resources/doner_logo_vecses.png";

export interface IMainPageViewProps {}

const MainPageView: React.FC<IMainPageViewProps> = (props) => {
    return (
        <div className="main-page">
            <RestaurantBlock
                backgournd_url={doner_17_ker}
                link="https://17ker.donerkebabtop.hu"
                className="align-right"
            />

            <RestaurantBlock
                backgournd_url={doner_vecses}
                link="http://vecses.donerkebabtop.hu/"
                className="align-left"
            />
        </div>
    );
};

export default MainPageView;
