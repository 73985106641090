import { Route, Switch, withRouter } from "react-router";
import E404PageNotFound from "./Components/WarnPages/View/E404";
import MainPageView from "./Components/MainPage/View/MainPage.view";

const Router: React.FC<any> = (props) => {
    return (
        <Switch>
            <Route component={MainPageView} path="/" exact />
            <Route component={E404PageNotFound} path="*" />
        </Switch>
    );
};

export default withRouter(Router);
